<template>
  <form class="search-form">
    <label for="description" class="search-form__label">Search for news</label>
    <input
      v-model="searchTerm"
      id="description"
      class="search-form__input input"
      type="text"
    />
    <button
      class="search-form__button button button--dark"
      @click.prevent="searchNews"
    >
      Search
    </button>
    <!-- <div class="search-form__field">
        <label for="categories" class="search-form__label">Category</label>
        <DropdownMenuWithFilter
          class="search-form__dropdown-menu"
          :options="categories"
          option-label-key="name"
          label-id="categories"
          :value="selectedCategory"
          @change="updateSelectedCategory"
        />
      </div> -->
  </form>
</template>

<script>
// import { NEWS_CATEGORIES } from "@/constants/dictionaries"
// import { addUniqueIdsToArrayOfObjects } from "@/utils"
// import DropdownMenuWithFilter from "@/components/DropdownMenuWithFilter"
import { isStringOnlyWhiteSpaces } from "@/utils"
import { mapActions } from "vuex"

export default {
  name: "SearchForm",
  // components: {
  //   DropdownMenuWithFilter
  // },
  data() {
    return {
      searchTerm: "",
      // categories: addUniqueIdsToArrayOfObjects(NEWS_CATEGORIES, "category"),
      // selectedCategory: "",
      isTitleOnlySearch: false
    }
  },
  computed: {
    searchType() {
      return this.isTitleOnlySearch ? "qInTitle" : "q"
    }
  },
  methods: {
    ...mapActions(["fetchUserSearch"]),
    searchNews() {
      console.log("?????", this.searchTerm, this.searchType)
      if (!isStringOnlyWhiteSpaces(this.searchTerm)) {
        this.fetchUserSearch(this.searchTerm, this.searchType)
      }
    },
    updateSelectedCategory(newVal) {
      this.selectedCategory = newVal.value
    }
  }
}
</script>

<style lang="scss" scoped>
.search-form {
  width: 100%;
  padding: $baseSpacing;
  font-size: 1.2rem;
  background: $white;

  @media only screen and (min-width: map-get($screenSizes, "large")) {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: $baseSpacingLarge;
  }

  &__label {
    display: block;
    margin-bottom: 0.6rem;

    @media only screen and (min-width: map-get($screenSizes, "large")) {
      width: 100%;
    }
  }

  &__input {
    height: 4rem;
    width: 100%;
    padding: 0.8rem 1.2rem;
    margin-bottom: 1.2rem;

    @media only screen and (min-width: map-get($screenSizes, "small")) {
      flex: 1;
    }

    @media only screen and (min-width: map-get($screenSizes, "large")) {
      flex: 1;
      margin-right: 1.2rem;
      margin-bottom: 0;
    }
  }

  &__button {
    height: 4rem;
    width: 9rem;
    margin-left: auto;
    display: inline-block;
  }
}
</style>
