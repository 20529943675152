<template>
  <div class="news-search" @wheel.stop="handleScroll">
    <div class="news-search__content">
      <SearchForm />
      <Tabs
        class="news-search__tabs"
        :class="{ 'news-search__tabs--has-active': selectedTabIndex >= 0 }"
      >
        <DynamicTab
          v-for="(search, index) in userSearches"
          :key="search.id"
          :tab="search"
          :is-active="index === selectedTabIndex"
          title-key="searchTerm"
          @tab-select="applySearch({ search, index })"
          @tab-delete="deleteUserSearchAndSetSelected"
        />
      </Tabs>
      <div
        ref="scrollable-area"
        class="news-search__scrollable-area"
        :style="{ height: `${scrollContainerHeight}px` }"
      >
        <div class="news-search__search-results-panel" ref="scroll-area">
          <template v-if="areThereArticles">
            <p
              v-for="(article, index) in selectedUserSearch?.articles"
              :key="index"
            >
              <ArticleCard :article="article" />
            </p>
          </template>
          <EmptyState v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash-es/debounce"
import { mapActions, mapGetters } from "vuex"
import SearchForm from "@/components/SearchForm"
import Tabs from "@/components/Tabs"
import DynamicTab from "@/components/DynamicTab"
import EmptyState from "@/components/EmptyState"
import ArticleCard from "@/components/ArticleCard"

export default {
  name: "NewsSearch",
  components: {
    SearchForm,
    Tabs,
    DynamicTab,
    EmptyState,
    ArticleCard
  },
  emits: ["tab-delete", "tab-select"],
  data() {
    return {
      scrollContainerHeight: null
    }
  },
  computed: {
    ...mapGetters(["userSearches", "selectedTabIndex", "selectedUserSearch"]),
    areThereArticles() {
      return this.selectedUserSearch?.articles?.length > 0
    }
  },
  methods: {
    ...mapActions(["applySearch", "deleteUserSearchAndSetSelected"]),
    setScrollContainerHeight: debounce(function () {
      if (this.$refs["scrollable-area"]) {
        this.scrollContainerHeight =
          window.innerHeight - this.$refs["scrollable-area"].offsetTop
      }
    }, 300),
    handleScroll(e) {
      console.log("?>", e, this.$refs["scroll-area"].scrollTop)

      this.$refs["scroll-area"].scrollTop += e.deltaY
    }
  },
  mounted() {
    window.addEventListener("resize", this.setScrollContainerHeight)

    if (this.userSearches?.length > 0) {
      this.selectedSearch = this.userSearches[0]
    }

    this.setScrollContainerHeight()
  },
  unmounted() {
    window.removeEventListener("resize")
  }
}
</script>

<style lang="scss" scoped>
.news-search {
  height: calc(100vh - 5rem);
  display: flex;
  justify-content: center;

  &__content {
    width: 100%;
    background: $white;

    @media only screen and (min-width: map-get($screenSizes, "small")) {
      max-width: map-get($screenSizes, "medium");
    }

    @media only screen and (min-width: map-get($screenSizes, "large")) {
      max-width: map-get($screenSizes, "large");
      // padding: $baseSpacing;
    }
  }

  &__tabs {
    // border-bottom: 0.5rem solid transparent;

    &--has-active {
      // border-color: hotpink;
    }
  }

  &__scrollable-area {
    // overflow-y: scroll;
    // display: row;
    // grid-template-rows: auto 1fr;
  }

  &__search-results-panel {
    height: 100%;
    padding: $baseSpacing;
    overflow: scroll;
    // background: aliceblue;

    @media only screen and (min-width: map-get($screenSizes, "large")) {
      padding: $baseSpacingLarge;
    }
  }
}
</style>
