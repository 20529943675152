<template>
  <Header />
  <NewsSearch />
</template>

<script>
import Header from "@/components/Header"
import NewsSearch from "@/views/NewsSearch"

export default {
  name: "App",
  components: {
    Header,
    NewsSearch
  }
}
</script>

<style lang="scss">
@import "./styles/index.scss";

#app {
  width: 100%;
  height: 100%;
  position: relative;
  background: $brandColor2;
}
</style>
