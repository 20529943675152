<template>
  <header class="header">
    <span class="header__logo">Search tabs demo</span>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 5rem;
  border-bottom: 0.1rem solid $black;
  color: $black;
  background: $white url("../assets/bg-test.svg") right 75% repeat-x;
  font-size: 1.6rem;
  padding: 1.2rem;
  display: flex;
  align-items: center;

  @media only screen and (min-width: map-get($screenSizes, "small")) {
    justify-content: center;
  }

  &__logo {
    @media only screen and (min-width: map-get($screenSizes, "small")) {
      padding: 0 1.4rem;
      width: map-get($screenSizes, "medium");
    }

    @media only screen and (min-width: map-get($screenSizes, "medium")) {
      padding: 0;
      width: map-get($screenSizes, "large");
    }
  }
}
</style>
