import { createStore } from "vuex"
// import { addUniqueIdsToArrayOfObjects } from "@/utils"
// import uniqueId from "lodash-es/uniqueId"
// import isEmpty from "lodash-es/isEmpty"
// import clone from "lodash-es/clone"
import { createUniqueIdInArray } from "@/utils"
import VuexPersistence from "vuex-persist"

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

export default createStore({
  state: {
    userSearches: [],
    selectedUserSearch: null,
    selectedTabIndex: -1
  },
  mutations: {
    addToUserSearches: (state, search) => {
      state.userSearches.push(search)
    },
    deleteUserSearch: (state, searchToDelete) => {
      state.userSearches = state.userSearches.filter(
        (search) => search.id !== searchToDelete.id
      )
    },
    selectUserSearch(state, selectedSearch) {
      state.selectedUserSearch = selectedSearch
    },
    setSelectedTabIndex(state, index) {
      state.selectedTabIndex = index
    }
  },
  actions: {
    fetchUserSearch(state, searchTerm, searchType = "q") {
      let searchTermEncoded = encodeURIComponent(searchTerm)

      fetch(
        `https://newsapi.org/v2/everything?${searchType}=${searchTermEncoded}&language=en&pageSize=20&page=1&apiKey=242d2f87f540466ab8d3901ca97dab1c`
      )
        .then((response) => response.json())
        .then((data) => {
          let newSearch = {
            ...data,
            searchTerm,
            id: createUniqueIdInArray(state.getters.userSearches, "search_")
          }
          state.commit("addToUserSearches", newSearch)
          state.commit("selectUserSearch", newSearch)
          state.commit(
            "setSelectedTabIndex",
            state.getters.userSearches.length - 1
          )
        })
    },
    deleteUserSearchAndSetSelected(state, search) {
      // console.log("TI", state.getters.selectedTabIndex)
      // console.log("IS", state.getters.isSearchAtSelectedIndex(search))

      if (state.getters.userSearches.length <= 1) {
        state.commit("setSelectedTabIndex", -1)
      } else if (
        state.getters.isSearchAtSelectedIndex(search) ||
        state.getters.selectedSearchIndex(search) <
          state.getters.selectedTabIndex
      ) {
        state.commit("setSelectedTabIndex", state.getters.selectedTabIndex - 1)
      }

      state.commit("deleteUserSearch", search)
    },
    applySearch(state, { search, index }) {
      // console.log("apply search params::", search, index)

      state.commit("selectUserSearch", search)
      state.commit("setSelectedTabIndex", index)
    }
  },
  getters: {
    userSearches: (state) => state.userSearches,
    selectedUserSearch: (state) => state.selectedUserSearch,
    selectedTabIndex: (state) => state.selectedTabIndex,
    isSearchAtSelectedIndex: (state, getters) => (search) => {
      return getters.selectedSearchIndex(search) == state.selectedTabIndex
    },
    selectedSearchIndex: (state) => (search) => {
      return state.userSearches.findIndex(
        (userSearch) => userSearch.id == search.id
      )
    }
  },
  modules: {},
  plugins: [vuexLocal.plugin]
})
