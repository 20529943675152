<template>
  <div class="article-card">
    <div class="article-card__info-container">
      <p class="article-card__info">{{ article.source?.name }}</p>
      <p class="article-card__info">{{ article.author || "-" }}</p>
      <p class="article-card__date">{{ publishedAtDateParsed }}</p>
    </div>
    <div class="article-card__main">
      <h3 class="article-card__heading">
        <a
          class="article-card__heading-link"
          :href="article.url"
          target="_blank"
          rel="noopener noreferrer"
        >
          {{ article.title }}
        </a>
      </h3>
      <div class="article-card__description-wrapper">
        <p class="article-card__description">{{ article.description }}</p>
      </div>
      <a
        class="article-card__description-link"
        :href="article.url"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div>
          <span>Read article</span>
          <BaseIcon class="article-card__icon">
            <IconOpenInNew />
          </BaseIcon>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { format } from "date-fns"
import BaseIcon from "@/components/BaseIcon"
import IconOpenInNew from "@/components/icons/IconOpenInNew"

export default {
  name: "ArticleCard",
  components: {
    BaseIcon,
    IconOpenInNew
  },
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  computed: {
    publishedAtDateParsed() {
      let isoDate = this.article.publishedAt
      // return isoDate ? isoDate.split("T")[0] : "-"
      return format(new Date(isoDate), "do MMMM yyyy")
    }
  }
}
</script>

<style lang="scss" scoped>
.article-card {
  border-bottom: 2px solid $brandColor2;
  margin-bottom: $baseSpacingLarge;
  padding-bottom: $baseSpacing;
  font-size: 1.4rem;

  &__info-container {
    // background: $brandColor3;
    display: flex;
    margin-bottom: 1.2rem;
  }

  &__info {
    background: $brandColor2;
    color: $black;
    font-weight: 500;
    padding: 0.6rem 0.8rem;
    margin-right: 0.2rem;
    max-width: 16rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__date {
    padding: 0.6rem 0.8rem;
    // margin-left: auto;
    font-weight: 500;
    color: $brandColor4;
  }

  &__heading {
    font-size: 2.8rem;
    margin-top: 0;
    margin-bottom: 2.4rem;
  }

  &__heading-link {
    color: $brandColor4;
    text-decoration-color: $brandColor2;
    text-decoration-thickness: 0.4rem;

    &:visited {
      color: $brandColor4;
      text-decoration-color: $brandColor2; // Todo:  Update these styles and make mixin
    }

    &:focus {
      @include focus-styles;
    }
  }

  &__description-wrapper {
    display: flex;
    justify-content: space-between;
  }

  // &__description {
  //   max-width: 68rem;
  // }

  &__description-link {
    display: flex;
    flex-direction: column-reverse;
    text-align: right;
    color: $brandColor4;
    text-decoration-color: $brandColor2;
    text-decoration-thickness: 0.2rem;
    white-space: nowrap;

    &:visited {
      color: $brandColor4;
      text-decoration-color: $brandColor2;
    }

    &:focus {
      @include focus-styles;
    }
  }

  // &__description {
  //   @media only screen and (min-width: map-get($screenSizes, "small")) {
  //     width: 70%;
  //   }
  // }

  // &__info {
  //   margin: 0;
  //   display: inline-block;
  //   // margin-right: 1.1rem;

  //   @media only screen and (min-width: map-get($screenSizes, "small")) {
  //     display: block;
  //     margin: 0;
  //   }
  // }

  &__left-column {
    flex: 4;
    padding: 1rem 0;
    margin-right: 2.4rem;
  }

  &__main {
    max-width: 68rem;
  }

  // &__right-column {
  //   display: flex;
  //   flex-direction: row;
  //   flex: 1;
  //   align-items: bottom;
  //   background: $brandColor2;
  //   padding: 1.1rem;
  //   justify-content: space-around;

  //   @media only screen and (min-width: map-get($screenSizes, "small")) {
  //     // display: block;
  //     text-align: right;
  //     padding: 1.2rem 2.4rem;
  //     justify-content: center;
  //     flex-direction: column;
  //   }
  // }

  &__icon {
    position: relative;
    top: 0.4rem;
    margin-left: 0.4rem;
  }
}
</style>
