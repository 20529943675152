<template>
  <div class="tabs">
    <slot />
  </div>
</template>

<script>
export default {
  name: "Tabs"
}
</script>

<style lang="scss" scoped>
.tabs {
  display: flex;
  overflow-x: scroll;
  background: $brandColor2;
}
</style>
