<template>
  <div class="empty-state">
    <h3 class="empty-state__heading">No results</h3>
    <p>Please enter another search</p>
  </div>
</template>

<script>
export default {
  name: "EmptyState"
}
</script>

<style lang="scss" scoped>
.empty-state {
  &__heading {
    font-weight: normal;
    font-style: italic;
  }
}
</style>
