import uniqueId from "lodash-es/uniqueId"

export const addUniqueIdsToArrayOfObjects = (array, prefix = "") => {
  return array.map((item) => {
    return { ...item, uid: uniqueId(`${prefix}_`) }
  })
}

export const isStringOnlyWhiteSpaces = (string) => {
  return string.trim().length == 0
}

export const createUniqueIdInArray = (array, prefix = "", idKey = "id") => {
  const generateIdString = () => {
    let idNumber = Math.floor(Math.random() * 90000)

    return `${prefix}${String(idNumber).substring(0, 4)}`
  }
  const idExists = (newId) => {
    return array.some((item) => item[idKey] == newId)
  }
  let uniqueId = generateIdString()

  while (idExists(uniqueId)) {
    console.log(idExists(uniqueId))
    uniqueId = generateIdString()
  }

  return uniqueId
}
