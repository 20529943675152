<template>
  <div
    class="tab"
    :class="{ 'tab--active': isActive }"
    role="button"
    tabindex="0"
    @click="selectTab"
    @keydown="handleKeydown"
    @mouseover="setIconHover(true)"
    @mouseleave="setIconHover(false)"
  >
    <span
      class="tab__title-text"
      :class="{ 'tab__title-text--active': isActive }"
      >{{ tab[titleKey] }}</span
    >
    <button
      class="tab__delete-button button--is-icon"
      title="foobar"
      @click.stop="deleteTab"
    >
      <BaseIcon
        class="tab__icon"
        :class="{
          'tab__icon--hover': iconIsHovered,
          'tab__icon--active': isActive
        }"
      >
        <IconClose />
      </BaseIcon>
    </button>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon"
import IconClose from "@/components/icons/IconClose"

export default {
  name: "DyanamicTab",
  components: {
    IconClose,
    BaseIcon
  },
  props: {
    tab: {
      type: Object,
      required: true
    },
    titleKey: {
      type: String,
      default: "title"
    },
    isActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      iconIsHovered: false
    }
  },
  methods: {
    deleteTab() {
      this.$emit("tab-delete", this.tab)
    },
    selectTab() {
      this.$emit("tab-select", this.tab)
    },
    handleKeydown(e) {
      if (e.keyCode == "13") {
        this.selectTab()
      }
    },
    setIconHover(isHovered) {
      this.iconIsHovered = isHovered
      console.log("hover", this.iconIsHovered)
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  transition: background-color 0.1s, color 0.1;
  // transition: all 2s;
  min-width: 9rem;
  max-width: 12rem;
  height: 4.2rem;
  position: relative;
  background: $brandColor1;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 4.2rem 0 2.4rem;
  margin-right: 0.2rem;
  font-weight: 500;
  font-size: 1.2rem;
  text-decoration-color: $brandColor1;

  // border-bottom: 0.5rem solid transparent;

  &:hover:not(.tab--active) {
    @include hover-styles;
  }

  &:focus {
    text-decoration: underline;
    text-decoration-thickness: 0.2rem;
    outline: none;
    text-underline-offset: 0.4rem;
    text-decoration-color: $focusColor;
  }

  &__title-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0.8rem 0;

    &--active {
      overflow: unset;
      text-overflow: initial;
    }
  }

  &__delete-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0.8rem;

    &:focus {
      @include focus-styles;
    }
  }

  &__icon {
    transition: fill 0.1s;
    fill: $white;

    &--hover {
      fill: $hoverTextColor;
    }

    &--active {
      fill: $black;
    }
  }

  // &__icon /deep/ svg {
  //   fill: $hoverTextColor;
  // }

  &--active {
    border-color: $brandColor5;
    background: $white;
    color: $black;
    text-decoration-color: $white;
    max-width: 100%;
    min-width: auto;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
